<template>
  <div id="app">

    <Header ></Header>
    <div  >
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script setup>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue';
import 'font-awesome/css/font-awesome.min.css';

import {onMounted, ref}  from 'vue'
const viewHeight = ref(400)
const header = ref(null)

onMounted(()=>{

})


</script>

<style scoped>
   #app{
    padding-top: 4.5rem;
    /* box-sizing: border-box;
    height: 100%;
    overflow: auto; */
   }
 
</style>
