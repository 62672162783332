import Vue from 'vue'

import App from './App.vue'
import router from './router'


import 'bulma/css/bulma.css'
import './assets/base.css'
import './assets/main.css'



new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')

